import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import spinner from "../img/spinner.svg"


interface Job {
    hash: string
    title: string,
    job_site: string,
    url: { href: string }
}

export const Jobs = () => {
    const [jobs, setJobs] = useState<Job[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function getJobs() {
            try {
                const {data} = await axios.get("api/jobs", {
                    headers: {
                        "Authorization": "Bearer " + sessionStorage.getItem("accessToken")
                    }, withCredentials: true
                })
                setJobs(data);
            } catch (err) {
                navigate("/login")
                return
            }
        }

        getJobs();
    }, [])

    return (
        <main className={"container"}>
            <h1 style={{textAlign: "center"}}>Stellenausschreibung auswählen:</h1>
            {
                !jobs.length ? (
                    <div style={{display: "flex", justifyContent: "center", marginTop: "100px"}}>
                        <img className={"spin"} src={spinner} width={50} alt={"loading animation"}/>
                    </div>
                ) : (
                    <table>
                        <thead>
                        <tr>
                            <th><p>Titel</p></th>
                            <th><p>Ort</p></th>
                        </tr>
                        </thead>
                        <tbody>
                        {jobs.map((job) => <TableRow job={job} key={job.hash}/>)}
                        </tbody>
                    </table>
                )
            }
        </main>
    );
};

const TableRow = ({job}: { job: Job }) => {
    return (
        <tr>
            <td>
                <a target={"_blank"} href={job.url.href}>
                    <p>{job.title}</p>
                </a>
            </td>
            <td>
                <p>{job.job_site}</p>
            </td>
        </tr>
    )
}