import React from 'react';
import logo from './img/logo.svg';
import './App.css';
import {
    BrowserRouter as Router,
    Route,
    Link, Routes
} from "react-router-dom";
import {Home} from "./Pages/Home";
import {Login} from "./Pages/Login";
import {Jobs} from "./Pages/Jobs";

function App() {
    return (
        <Router>
            <div>
                <header>
                    <div className={"container"}>
                        <Link to={"/"}>
                            <img className={"logo"} src={logo} alt={"Logo Saubermacher"}/>
                        </Link>
                    </div>
                </header>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="login" element={<Login/>}/>
                    <Route path="/jobs" element={<Jobs/>}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
