import {Link} from "react-router-dom";

export const Home = () => {
    return (
        <main className={"container"}>
            <section>
                <h1> Willkommen</h1>
                <p>
                    <b>Herzlich Willkommen im Personaldienstleister-Portal von Saubermacher.</b>
                </p>
                <p>In diesem Portal ermöglichen wir Ihnen, nach der Auftragserteilung, die Unterlagen der
                    Bewerber:Innen anzugeben bzw. hochzuladen.</p>
                <p>Benötigen Sie die Anmeldedaten oder haben Sie noch eine Frage? Dann kontaktieren Sie bitte Ihre
                    Ansprechperson im Konzern Personalmanagement oder wenden Sie sich unter der Nummer +43 (0) 59
                    800 1300 an uns.</p>
                <p>Wir freuen uns über die Zusammenarbeit!</p>
                <p>Hier geht es zum {
                    <Link to={"/Login"}>
                        Upload-Bereich
                    </Link>
                }
                </p>
            </section>
        </main>
    );
};