import {FormEvent, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";


export const Login = () => {
    const [inputValue, setInputValue] = useState("");
    const [showWarning, setShowWarning] = useState(false)
    const navigate = useNavigate();

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        async function login() {
            try {
                const {data} = await axios.post("api/login", {password: inputValue});
                sessionStorage.setItem("accessToken", data.accessToken)
                navigate("/jobs")
            } catch (err) {
                setShowWarning(true);
            }
            setInputValue("");
        }

        login();
    }

    return (
        <main className={"container"}>
            <section>
                <h1>Password Eingabe</h1>
                <form onSubmit={handleSubmit}>
                    <label>PASSWORT</label>
                    <div style={{display: "flex"}}>
                        <input type={"password"} value={inputValue}
                               onChange={(e) => setInputValue(e.target.value)}/>
                        <button type={"submit"}>Absenden</button>
                    </div>
                </form>
                {showWarning && (
                    <div>
                        <p style={{color: "red", fontWeight: "bold", marginBottom: 0}}>Falsches Passwort!</p>
                        <p style={{marginTop: 0}}>Kontaktieren Sie bitte Ihre Ansprechperson im Konzern
                            Personalmanagement oder wenden Sie sich
                            unter der Nummer +43 (0) 59 800 1300 an uns um Zugang zu erhalten.</p>
                    </div>
                )}
            </section>
        </main>
    );
};